<template>
  <div class="pt-10">
    <vx-card>
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'parent-dashboard'}">Dashboard</router-link>
            <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class="">Guardians</a>
          </li>
        </ul>
      </div>
      <vs-divider class="mb-8"></vs-divider>
      <vs-table
        ref="table"
        :sst="true"
        :max-items="10"
        :data="guardians"
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap flex-grow justify-between" >
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-5">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-xs="12" vs-sm="6" vs-lg="6">

            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="left" vs-xs="12" vs-sm="6" vs-lg="6">
              <vs-button color="primary" type="filled" @click="addNewGuardian">Add Guardian</vs-button>
            </vs-col>
          </vs-row>
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Relationship to child</vs-th>
          <vs-th>Mobile</vs-th>
          <vs-th>Address</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.parentDetail.fullName">
              {{tr.parentDetail.title}} {{tr.parentDetail.fullName | capitalize }}
            </vs-td>
            <vs-td :data="tr.parentDetail.email">
              {{tr.parentDetail.email}}
            </vs-td>
            <vs-td :data="tr.parents.relationToChild">
              {{tr.parents.relationToChild}}
            </vs-td>
            <vs-td :data="tr.parentDetail.mobileNumber">
              {{tr.parentDetail.mobileNumber}}
            </vs-td>
            <vs-td>
              <div v-if="tr.parentDetail.address">
              {{tr.parentDetail.address.street}} <br>
              {{tr.parentDetail.address.suburb}}, {{tr.parentDetail.address.postCode}}
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <a href="#" class="nav-link d-flex align-items-center active" @click="updateGuardian(tr.parentDetail._id)">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {

  data() {
    return {
      noDataText :'Currently no Guardian for the child.',
      childId: null,
      guardians: [],
    };
  },
  methods: {
    ...mapActions("parent", [
      "fetchAllGuardians",
      "deleteGuardian",
    ]),
    addNewGuardian(){
      this.$router.push({ name: 'guardian-add'});
    },
    updateGuardian(id){
      this.$router.push({ name: 'guardian-edit',params: { id } });
    },
    async getGuardians() {
      await this.fetchAllGuardians(this.childId).then(res => {
        this.guardians = this.$store.state.parent.parents;
      })
    },

  },
  created() {
    this.childId = this.currentChild._id;
    this.getGuardians();
  },
  computed: {
    currentChild(){
      return this.$store.state.AppParentSelectedChild;
    }
  },
}
</script>
